import React from "react"
import Header from "./header.js"
import "./layout.css"
import Years from "./years"


const Page = ({ children }) => {
  return (
    <div id="wrapper">
      <Header />
      <hr />

      {children}
      <Years />
    </div>
  )
}

export default Page