import React from "react"
import Years from '../years.json'

export default () => {
  return (
    <div>
      <span>Yearly Indexes: </span>
      {Years.map((year) => (
        <span>
          <a href={`/${year}`}>
            <span>{year}</span>
          </a>
          &#160;
        </span>
      ))}
    </div>
  )
}