import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import "./header.css"
import { Helmet } from "react-helmet"

const ListLink = (props) => (
    <li>
        <Link to={props.to}>{props.children}</Link>
    </li>
)
export default function Header() {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <header>
        <Helmet title={data.site.siteMetadata.title} defer={false} />
        <ul>
        <ListLink to={`/about/`}>About</ListLink>
        </ul>
        <Link to={`/`}>
        <h1>{data.site.siteMetadata.title}</h1>
        </Link>
    </header>
  )
}